import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  const url = "https://lightsail.aws.amazon.com/ls/webapp/home/instances";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => (
  <Redirect goto_url={url} />
);

export default IndexPage;
